import React, { Component } from 'react';

import ReactPullToRefresh from 'react-pull-to-refresh';
import axios from 'axios';

import './location.scss';
import './pullToRefresh/pullToRefresh.scss';

let dev = false;
if (window.location.hostname === 'localhost') {
	dev = true;
}

let params = {
	weather: {
		sun: {
			inWords: {
				de: 'Die verdammte <b>Liebe</b> ist die Luft',
				en: 'Fucking love is the air',
			},
			details: 'Es ist sonnig, für Alle die es nicht mitbekommen haben.',
		},
		rain: {
			inWords: {
				de: 'Es regnet jetzt <b>verdammt</b> nochmal.',
				en: "It's fucking <b>raining</b> now.",
			},
			details:
				'Du kannst nach draußen schauen, um mehr Informationen zu erhalten.',
		},
		801: {
			inWords: {
				de: 'Ein paar <b>Wolken</b> am Himmel.',
			},
		},
		clouds: {
			inWords: {
				de: 'Einfach <b>verdammt</b> grau.',
				en: "It's fucking <b>clouds</b> now.",
			},
			details: 'Wolken überall. Aber es ist warm.',
		},
		clear: {
			inWords: {
				de: 'Der Himmel ist ja mal <b>mega</b> klar.',
				en: "It's fucking <b>clouds</b> now.",
			},
			details: `Strahlend blauer Himmel – besser geht's nicht!`,
		},
		snow: {
			inWords: {
				de: 'Eiskalt - wie im verdammten KÜhlschrank.',
				en: 'Freezing cold like a fucking fridge',
			},
			details: 'Keine Beleidigung für deinen Kühlschrank.',
		},
	},
};

function log(message) {
	if (dev) {
		console.log(message);
	}
}

class Location extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		if (false) {
			this.state = {
				city: 'Leipzig',

				lat: 51.3708,
				lon: 12.3719,
				timezone: 'Europe/Berlin',
				timezone_offset: 7200,
				current: {
					dt: 1743747842,
					sunrise: 1743741537,
					sunset: 1743788858,
					temp: 7.05,
					feels_like: 6.24,
					pressure: 1023,
					humidity: 80,
					dew_point: 3.84,
					uvi: 0.29,
					clouds: 0,
					visibility: 10000,
					wind_speed: 1.54,
					wind_deg: 160,
					weather: [
						{
							id: 800,
							main: 'Clear',
							description: 'Klarer Himmel',
							icon: '01d',
						},
					],
				},
				minutely: [
					{
						dt: 1743747900,
						precipitation: 0,
					},
					{
						dt: 1743747960,
						precipitation: 0,
					},
					{
						dt: 1743748020,
						precipitation: 0,
					},
					{
						dt: 1743748080,
						precipitation: 0,
					},
					{
						dt: 1743748140,
						precipitation: 0,
					},
					{
						dt: 1743748200,
						precipitation: 0,
					},
					{
						dt: 1743748260,
						precipitation: 0,
					},
					{
						dt: 1743748320,
						precipitation: 0,
					},
					{
						dt: 1743748380,
						precipitation: 0,
					},
					{
						dt: 1743748440,
						precipitation: 0,
					},
					{
						dt: 1743748500,
						precipitation: 0,
					},
					{
						dt: 1743748560,
						precipitation: 0,
					},
					{
						dt: 1743748620,
						precipitation: 0,
					},
					{
						dt: 1743748680,
						precipitation: 0,
					},
					{
						dt: 1743748740,
						precipitation: 0,
					},
					{
						dt: 1743748800,
						precipitation: 0,
					},
					{
						dt: 1743748860,
						precipitation: 0,
					},
					{
						dt: 1743748920,
						precipitation: 0,
					},
					{
						dt: 1743748980,
						precipitation: 0,
					},
					{
						dt: 1743749040,
						precipitation: 0,
					},
					{
						dt: 1743749100,
						precipitation: 0,
					},
					{
						dt: 1743749160,
						precipitation: 0,
					},
					{
						dt: 1743749220,
						precipitation: 0,
					},
					{
						dt: 1743749280,
						precipitation: 0,
					},
					{
						dt: 1743749340,
						precipitation: 0,
					},
					{
						dt: 1743749400,
						precipitation: 0,
					},
					{
						dt: 1743749460,
						precipitation: 0,
					},
					{
						dt: 1743749520,
						precipitation: 0,
					},
					{
						dt: 1743749580,
						precipitation: 0,
					},
					{
						dt: 1743749640,
						precipitation: 0,
					},
					{
						dt: 1743749700,
						precipitation: 0,
					},
					{
						dt: 1743749760,
						precipitation: 0,
					},
					{
						dt: 1743749820,
						precipitation: 0,
					},
					{
						dt: 1743749880,
						precipitation: 0,
					},
					{
						dt: 1743749940,
						precipitation: 0,
					},
					{
						dt: 1743750000,
						precipitation: 0,
					},
					{
						dt: 1743750060,
						precipitation: 0,
					},
					{
						dt: 1743750120,
						precipitation: 0,
					},
					{
						dt: 1743750180,
						precipitation: 0,
					},
					{
						dt: 1743750240,
						precipitation: 0,
					},
					{
						dt: 1743750300,
						precipitation: 0,
					},
					{
						dt: 1743750360,
						precipitation: 0,
					},
					{
						dt: 1743750420,
						precipitation: 0,
					},
					{
						dt: 1743750480,
						precipitation: 0,
					},
					{
						dt: 1743750540,
						precipitation: 0,
					},
					{
						dt: 1743750600,
						precipitation: 0,
					},
					{
						dt: 1743750660,
						precipitation: 0,
					},
					{
						dt: 1743750720,
						precipitation: 0,
					},
					{
						dt: 1743750780,
						precipitation: 0,
					},
					{
						dt: 1743750840,
						precipitation: 0,
					},
					{
						dt: 1743750900,
						precipitation: 0,
					},
					{
						dt: 1743750960,
						precipitation: 0,
					},
					{
						dt: 1743751020,
						precipitation: 0,
					},
					{
						dt: 1743751080,
						precipitation: 0,
					},
					{
						dt: 1743751140,
						precipitation: 0,
					},
					{
						dt: 1743751200,
						precipitation: 0,
					},
					{
						dt: 1743751260,
						precipitation: 0,
					},
					{
						dt: 1743751320,
						precipitation: 0,
					},
					{
						dt: 1743751380,
						precipitation: 0,
					},
					{
						dt: 1743751440,
						precipitation: 0,
					},
				],
				hourly: [
					{
						dt: 1743746400,
						temp: 7.05,
						feels_like: 7.05,
						pressure: 1023,
						humidity: 80,
						dew_point: 3.84,
						uvi: 0.29,
						clouds: 0,
						visibility: 10000,
						wind_speed: 1.19,
						wind_deg: 106,
						wind_gust: 1.17,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743750000,
						temp: 7.43,
						feels_like: 7.43,
						pressure: 1023,
						humidity: 79,
						dew_point: 4.03,
						uvi: 0.83,
						clouds: 0,
						visibility: 10000,
						wind_speed: 1.26,
						wind_deg: 119,
						wind_gust: 1.33,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743753600,
						temp: 9.32,
						feels_like: 8.71,
						pressure: 1023,
						humidity: 76,
						dew_point: 5.31,
						uvi: 1.67,
						clouds: 0,
						visibility: 10000,
						wind_speed: 1.66,
						wind_deg: 126,
						wind_gust: 1.76,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743757200,
						temp: 12.25,
						feels_like: 11.38,
						pressure: 1022,
						humidity: 71,
						dew_point: 7.15,
						uvi: 2.64,
						clouds: 0,
						visibility: 10000,
						wind_speed: 1.75,
						wind_deg: 133,
						wind_gust: 2.02,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743760800,
						temp: 15.22,
						feels_like: 14.47,
						pressure: 1022,
						humidity: 64,
						dew_point: 8.46,
						uvi: 3.44,
						clouds: 0,
						visibility: 10000,
						wind_speed: 1.68,
						wind_deg: 131,
						wind_gust: 1.97,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743764400,
						temp: 18.87,
						feels_like: 18.27,
						pressure: 1021,
						humidity: 56,
						dew_point: 7.04,
						uvi: 3.81,
						clouds: 0,
						visibility: 10000,
						wind_speed: 1.49,
						wind_deg: 126,
						wind_gust: 1.77,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743768000,
						temp: 19.86,
						feels_like: 19.13,
						pressure: 1020,
						humidity: 47,
						dew_point: 6.91,
						uvi: 3.66,
						clouds: 0,
						visibility: 10000,
						wind_speed: 1.24,
						wind_deg: 110,
						wind_gust: 1.46,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743771600,
						temp: 20.3,
						feels_like: 19.56,
						pressure: 1019,
						humidity: 45,
						dew_point: 6.82,
						uvi: 3.01,
						clouds: 0,
						visibility: 10000,
						wind_speed: 0.87,
						wind_deg: 108,
						wind_gust: 1.1,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743775200,
						temp: 20.79,
						feels_like: 20.07,
						pressure: 1018,
						humidity: 44,
						dew_point: 6.54,
						uvi: 2.08,
						clouds: 0,
						visibility: 10000,
						wind_speed: 0.39,
						wind_deg: 99,
						wind_gust: 0.52,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743778800,
						temp: 20.67,
						feels_like: 19.94,
						pressure: 1018,
						humidity: 44,
						dew_point: 6.33,
						uvi: 1.16,
						clouds: 0,
						visibility: 10000,
						wind_speed: 0.68,
						wind_deg: 350,
						wind_gust: 0.53,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743782400,
						temp: 20.04,
						feels_like: 19.41,
						pressure: 1017,
						humidity: 50,
						dew_point: 7.91,
						uvi: 0.48,
						clouds: 0,
						visibility: 10000,
						wind_speed: 1.33,
						wind_deg: 355,
						wind_gust: 1.62,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743786000,
						temp: 18.5,
						feels_like: 17.89,
						pressure: 1017,
						humidity: 57,
						dew_point: 7.45,
						uvi: 0.12,
						clouds: 0,
						visibility: 10000,
						wind_speed: 1.47,
						wind_deg: 357,
						wind_gust: 1.53,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743789600,
						temp: 15.48,
						feels_like: 14.68,
						pressure: 1017,
						humidity: 61,
						dew_point: 6.23,
						uvi: 0,
						clouds: 0,
						visibility: 10000,
						wind_speed: 1.4,
						wind_deg: 358,
						wind_gust: 1.54,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743793200,
						temp: 12.38,
						feels_like: 11.4,
						pressure: 1017,
						humidity: 66,
						dew_point: 6.25,
						uvi: 0,
						clouds: 0,
						visibility: 10000,
						wind_speed: 1.16,
						wind_deg: 346,
						wind_gust: 1.27,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743796800,
						temp: 11.74,
						feels_like: 10.77,
						pressure: 1017,
						humidity: 69,
						dew_point: 6.24,
						uvi: 0,
						clouds: 0,
						visibility: 10000,
						wind_speed: 1.53,
						wind_deg: 332,
						wind_gust: 1.48,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743800400,
						temp: 11.15,
						feels_like: 10.2,
						pressure: 1016,
						humidity: 72,
						dew_point: 6.23,
						uvi: 0,
						clouds: 0,
						visibility: 10000,
						wind_speed: 1.89,
						wind_deg: 326,
						wind_gust: 1.94,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743804000,
						temp: 10.48,
						feels_like: 9.57,
						pressure: 1016,
						humidity: 76,
						dew_point: 6.53,
						uvi: 0,
						clouds: 2,
						visibility: 10000,
						wind_speed: 2.12,
						wind_deg: 337,
						wind_gust: 2.18,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743807600,
						temp: 10.02,
						feels_like: 9.17,
						pressure: 1016,
						humidity: 80,
						dew_point: 6.72,
						uvi: 0,
						clouds: 4,
						visibility: 10000,
						wind_speed: 2.01,
						wind_deg: 326,
						wind_gust: 2.18,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743811200,
						temp: 9.56,
						feels_like: 7.97,
						pressure: 1016,
						humidity: 82,
						dew_point: 6.76,
						uvi: 0,
						clouds: 20,
						visibility: 10000,
						wind_speed: 3.02,
						wind_deg: 336,
						wind_gust: 3.28,
						weather: [
							{
								id: 801,
								main: 'Clouds',
								description: 'Ein paar Wolken',
								icon: '02n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743814800,
						temp: 8.62,
						feels_like: 7.06,
						pressure: 1016,
						humidity: 88,
						dew_point: 6.84,
						uvi: 0,
						clouds: 0,
						visibility: 10000,
						wind_speed: 2.69,
						wind_deg: 337,
						wind_gust: 3.12,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743818400,
						temp: 8.1,
						feels_like: 6.59,
						pressure: 1015,
						humidity: 91,
						dew_point: 6.8,
						uvi: 0,
						clouds: 0,
						visibility: 10000,
						wind_speed: 2.48,
						wind_deg: 349,
						wind_gust: 2.97,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743822000,
						temp: 7.73,
						feels_like: 5.88,
						pressure: 1016,
						humidity: 91,
						dew_point: 6.39,
						uvi: 0,
						clouds: 34,
						visibility: 10000,
						wind_speed: 2.85,
						wind_deg: 356,
						wind_gust: 4.32,
						weather: [
							{
								id: 802,
								main: 'Clouds',
								description: 'Mäßig bewölkt',
								icon: '03n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743825600,
						temp: 7.19,
						feels_like: 5.16,
						pressure: 1016,
						humidity: 86,
						dew_point: 5.13,
						uvi: 0,
						clouds: 51,
						visibility: 10000,
						wind_speed: 2.96,
						wind_deg: 353,
						wind_gust: 5.41,
						weather: [
							{
								id: 803,
								main: 'Clouds',
								description: 'Überwiegend bewölkt',
								icon: '04n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743829200,
						temp: 6.56,
						feels_like: 4.34,
						pressure: 1016,
						humidity: 79,
						dew_point: 3.14,
						uvi: 0,
						clouds: 60,
						visibility: 10000,
						wind_speed: 3.06,
						wind_deg: 350,
						wind_gust: 5.61,
						weather: [
							{
								id: 803,
								main: 'Clouds',
								description: 'Überwiegend bewölkt',
								icon: '04d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743832800,
						temp: 7.14,
						feels_like: 4.95,
						pressure: 1017,
						humidity: 75,
						dew_point: 3.04,
						uvi: 0.28,
						clouds: 67,
						visibility: 10000,
						wind_speed: 3.2,
						wind_deg: 357,
						wind_gust: 6.56,
						weather: [
							{
								id: 803,
								main: 'Clouds',
								description: 'Überwiegend bewölkt',
								icon: '04d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743836400,
						temp: 8.47,
						feels_like: 6.15,
						pressure: 1017,
						humidity: 75,
						dew_point: 4.35,
						uvi: 0.82,
						clouds: 60,
						visibility: 10000,
						wind_speed: 3.95,
						wind_deg: 20,
						wind_gust: 5.7,
						weather: [
							{
								id: 803,
								main: 'Clouds',
								description: 'Überwiegend bewölkt',
								icon: '04d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743840000,
						temp: 9.66,
						feels_like: 7.32,
						pressure: 1018,
						humidity: 69,
						dew_point: 4.26,
						uvi: 1.63,
						clouds: 58,
						visibility: 10000,
						wind_speed: 4.6,
						wind_deg: 30,
						wind_gust: 5.89,
						weather: [
							{
								id: 803,
								main: 'Clouds',
								description: 'Überwiegend bewölkt',
								icon: '04d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743843600,
						temp: 10.53,
						feels_like: 9.1,
						pressure: 1018,
						humidity: 56,
						dew_point: 2.23,
						uvi: 2.55,
						clouds: 70,
						visibility: 10000,
						wind_speed: 5.2,
						wind_deg: 34,
						wind_gust: 6.07,
						weather: [
							{
								id: 803,
								main: 'Clouds',
								description: 'Überwiegend bewölkt',
								icon: '04d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743847200,
						temp: 11.01,
						feels_like: 9.42,
						pressure: 1018,
						humidity: 48,
						dew_point: 0.62,
						uvi: 3.05,
						clouds: 79,
						visibility: 10000,
						wind_speed: 5.24,
						wind_deg: 38,
						wind_gust: 6.02,
						weather: [
							{
								id: 803,
								main: 'Clouds',
								description: 'Überwiegend bewölkt',
								icon: '04d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743850800,
						temp: 11.28,
						feels_like: 9.64,
						pressure: 1018,
						humidity: 45,
						dew_point: -0.13,
						uvi: 2.8,
						clouds: 83,
						visibility: 10000,
						wind_speed: 5.62,
						wind_deg: 35,
						wind_gust: 6.29,
						weather: [
							{
								id: 803,
								main: 'Clouds',
								description: 'Überwiegend bewölkt',
								icon: '04d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743854400,
						temp: 11.73,
						feels_like: 10.03,
						pressure: 1018,
						humidity: 41,
						dew_point: -0.88,
						uvi: 3.16,
						clouds: 86,
						visibility: 10000,
						wind_speed: 5.53,
						wind_deg: 30,
						wind_gust: 6.69,
						weather: [
							{
								id: 804,
								main: 'Clouds',
								description: 'Bedeckt',
								icon: '04d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743858000,
						temp: 11.93,
						feels_like: 10.25,
						pressure: 1018,
						humidity: 41,
						dew_point: -0.93,
						uvi: 3.01,
						clouds: 100,
						visibility: 10000,
						wind_speed: 5.71,
						wind_deg: 29,
						wind_gust: 7.13,
						weather: [
							{
								id: 804,
								main: 'Clouds',
								description: 'Bedeckt',
								icon: '04d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743861600,
						temp: 11.9,
						feels_like: 10.24,
						pressure: 1018,
						humidity: 42,
						dew_point: -0.67,
						uvi: 2.05,
						clouds: 100,
						visibility: 10000,
						wind_speed: 6.18,
						wind_deg: 27,
						wind_gust: 7.48,
						weather: [
							{
								id: 804,
								main: 'Clouds',
								description: 'Bedeckt',
								icon: '04d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743865200,
						temp: 11.27,
						feels_like: 9.55,
						pressure: 1018,
						humidity: 42,
						dew_point: -1.05,
						uvi: 1.17,
						clouds: 100,
						visibility: 10000,
						wind_speed: 6.91,
						wind_deg: 29,
						wind_gust: 7.87,
						weather: [
							{
								id: 804,
								main: 'Clouds',
								description: 'Bedeckt',
								icon: '04d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743868800,
						temp: 10.2,
						feels_like: 8.45,
						pressure: 1019,
						humidity: 45,
						dew_point: -1.18,
						uvi: 0.49,
						clouds: 81,
						visibility: 10000,
						wind_speed: 6.68,
						wind_deg: 33,
						wind_gust: 7.63,
						weather: [
							{
								id: 803,
								main: 'Clouds',
								description: 'Überwiegend bewölkt',
								icon: '04d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743872400,
						temp: 7.99,
						feels_like: 4.31,
						pressure: 1020,
						humidity: 46,
						dew_point: -2.97,
						uvi: 0.13,
						clouds: 68,
						visibility: 10000,
						wind_speed: 7.16,
						wind_deg: 39,
						wind_gust: 10.48,
						weather: [
							{
								id: 803,
								main: 'Clouds',
								description: 'Überwiegend bewölkt',
								icon: '04d',
							},
						],
						pop: 0,
					},
					{
						dt: 1743876000,
						temp: 5.67,
						feels_like: 1.29,
						pressure: 1021,
						humidity: 45,
						dew_point: -5.21,
						uvi: 0,
						clouds: 68,
						visibility: 10000,
						wind_speed: 7.25,
						wind_deg: 46,
						wind_gust: 11.92,
						weather: [
							{
								id: 803,
								main: 'Clouds',
								description: 'Überwiegend bewölkt',
								icon: '04n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743879600,
						temp: 4.32,
						feels_like: -0.25,
						pressure: 1022,
						humidity: 48,
						dew_point: -5.64,
						uvi: 0,
						clouds: 9,
						visibility: 10000,
						wind_speed: 6.71,
						wind_deg: 47,
						wind_gust: 12.22,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743883200,
						temp: 3.3,
						feels_like: -1.34,
						pressure: 1023,
						humidity: 51,
						dew_point: -5.71,
						uvi: 0,
						clouds: 5,
						visibility: 10000,
						wind_speed: 6.19,
						wind_deg: 49,
						wind_gust: 11.94,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743886800,
						temp: 2.48,
						feels_like: -2.33,
						pressure: 1024,
						humidity: 54,
						dew_point: -5.98,
						uvi: 0,
						clouds: 3,
						visibility: 10000,
						wind_speed: 6.05,
						wind_deg: 49,
						wind_gust: 11.96,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743890400,
						temp: 1.78,
						feels_like: -3,
						pressure: 1024,
						humidity: 56,
						dew_point: -5.91,
						uvi: 0,
						clouds: 2,
						visibility: 10000,
						wind_speed: 5.59,
						wind_deg: 43,
						wind_gust: 11.3,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743894000,
						temp: 0.98,
						feels_like: -3.74,
						pressure: 1025,
						humidity: 58,
						dew_point: -6.25,
						uvi: 0,
						clouds: 2,
						visibility: 10000,
						wind_speed: 5.09,
						wind_deg: 40,
						wind_gust: 11.02,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743897600,
						temp: 0.28,
						feels_like: -4.59,
						pressure: 1025,
						humidity: 60,
						dew_point: -6.49,
						uvi: 0,
						clouds: 1,
						visibility: 10000,
						wind_speed: 5.04,
						wind_deg: 38,
						wind_gust: 10.62,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743901200,
						temp: -0.42,
						feels_like: -5.21,
						pressure: 1026,
						humidity: 62,
						dew_point: -6.76,
						uvi: 0,
						clouds: 0,
						visibility: 10000,
						wind_speed: 4.62,
						wind_deg: 37,
						wind_gust: 10.13,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743904800,
						temp: -1.25,
						feels_like: -5.87,
						pressure: 1026,
						humidity: 65,
						dew_point: -6.97,
						uvi: 0,
						clouds: 0,
						visibility: 10000,
						wind_speed: 4.07,
						wind_deg: 36,
						wind_gust: 9.17,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743908400,
						temp: -1.85,
						feels_like: -6.36,
						pressure: 1027,
						humidity: 67,
						dew_point: -7.16,
						uvi: 0,
						clouds: 0,
						visibility: 10000,
						wind_speed: 3.74,
						wind_deg: 29,
						wind_gust: 8.91,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743912000,
						temp: -2.23,
						feels_like: -6.56,
						pressure: 1027,
						humidity: 67,
						dew_point: -7.5,
						uvi: 0,
						clouds: 0,
						visibility: 10000,
						wind_speed: 3.42,
						wind_deg: 24,
						wind_gust: 7.99,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01n',
							},
						],
						pop: 0,
					},
					{
						dt: 1743915600,
						temp: -2.17,
						feels_like: -6.46,
						pressure: 1028,
						humidity: 66,
						dew_point: -7.57,
						uvi: 0,
						clouds: 0,
						visibility: 10000,
						wind_speed: 3.39,
						wind_deg: 20,
						wind_gust: 8.49,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01d',
							},
						],
						pop: 0,
					},
				],
				daily: [
					{
						dt: 1743764400,
						sunrise: 1743741537,
						sunset: 1743788858,
						moonrise: 1743753120,
						moonset: 1743729480,
						moon_phase: 0.23,
						summary: 'There will be clear sky today',
						temp: {
							day: 18.87,
							min: 5.59,
							max: 20.79,
							night: 11.15,
							eve: 18.5,
							morn: 6.69,
						},
						feels_like: {
							day: 18.27,
							night: 10.2,
							eve: 17.89,
							morn: 6.69,
						},
						pressure: 1021,
						humidity: 56,
						dew_point: 7.04,
						wind_speed: 2.55,
						wind_deg: 105,
						wind_gust: 2.56,
						weather: [
							{
								id: 800,
								main: 'Clear',
								description: 'Klarer Himmel',
								icon: '01d',
							},
						],
						clouds: 0,
						pop: 0,
						uvi: 3.81,
					},
					{
						dt: 1743850800,
						sunrise: 1743827802,
						sunset: 1743875358,
						moonrise: 1743843960,
						moonset: 1743818820,
						moon_phase: 0.25,
						summary:
							'Expect a day of partly cloudy with clear spells',
						temp: {
							day: 11.28,
							min: 2.48,
							max: 11.93,
							night: 2.48,
							eve: 7.99,
							morn: 6.56,
						},
						feels_like: {
							day: 9.64,
							night: -2.33,
							eve: 4.31,
							morn: 4.34,
						},
						pressure: 1018,
						humidity: 45,
						dew_point: -0.13,
						wind_speed: 7.25,
						wind_deg: 46,
						wind_gust: 12.22,
						weather: [
							{
								id: 803,
								main: 'Clouds',
								description: 'Überwiegend bewölkt',
								icon: '04d',
							},
						],
						clouds: 83,
						pop: 0,
						uvi: 3.16,
					},
					{
						dt: 1743937200,
						sunrise: 1743914068,
						sunset: 1743961857,
						moonrise: 1743935100,
						moonset: 1743907200,
						moon_phase: 0.29,
						summary:
							'Expect a day of partly cloudy with clear spells',
						temp: {
							day: 5.74,
							min: -2.23,
							max: 7.39,
							night: 0.03,
							eve: 5.2,
							morn: -2.17,
						},
						feels_like: {
							day: 2.02,
							night: -3.73,
							eve: 2.01,
							morn: -6.46,
						},
						pressure: 1027,
						humidity: 36,
						dew_point: -8.06,
						wind_speed: 6.16,
						wind_deg: 38,
						wind_gust: 11.3,
						weather: [
							{
								id: 803,
								main: 'Clouds',
								description: 'Überwiegend bewölkt',
								icon: '04d',
							},
						],
						clouds: 67,
						pop: 0,
						uvi: 3.65,
					},
					{
						dt: 1744023600,
						sunrise: 1744000334,
						sunset: 1744048356,
						moonrise: 1744026300,
						moonset: 1743994980,
						moon_phase: 0.33,
						summary:
							'There will be clear sky until morning, then partly cloudy',
						temp: {
							day: 8.48,
							min: -2.55,
							max: 9.33,
							night: 4.7,
							eve: 5.83,
							morn: -2.21,
						},
						feels_like: {
							day: 6.4,
							night: 2.22,
							eve: 3.83,
							morn: -2.21,
						},
						pressure: 1027,
						humidity: 41,
						dew_point: -3.93,
						wind_speed: 4.11,
						wind_deg: 321,
						wind_gust: 6.43,
						weather: [
							{
								id: 804,
								main: 'Clouds',
								description: 'Bedeckt',
								icon: '04d',
							},
						],
						clouds: 93,
						pop: 0,
						uvi: 3.48,
					},
					{
						dt: 1744110000,
						sunrise: 1744086601,
						sunset: 1744134856,
						moonrise: 1744117320,
						moonset: 1744082340,
						moon_phase: 0.36,
						summary: 'There will be partly cloudy today',
						temp: {
							day: 9.24,
							min: 4.06,
							max: 9.24,
							night: 6.4,
							eve: 7.23,
							morn: 4.06,
						},
						feels_like: {
							day: 7.9,
							night: 4.94,
							eve: 6.47,
							morn: 1.33,
						},
						pressure: 1029,
						humidity: 59,
						dew_point: 1.66,
						wind_speed: 3.06,
						wind_deg: 337,
						wind_gust: 6.78,
						weather: [
							{
								id: 804,
								main: 'Clouds',
								description: 'Bedeckt',
								icon: '04d',
							},
						],
						clouds: 86,
						pop: 0,
						uvi: 3.4,
					},
					{
						dt: 1744196400,
						sunrise: 1744172868,
						sunset: 1744221355,
						moonrise: 1744208100,
						moonset: 1744169460,
						moon_phase: 0.39,
						summary: 'There will be partly cloudy today',
						temp: {
							day: 11.74,
							min: 2.66,
							max: 12.75,
							night: 9.27,
							eve: 9.99,
							morn: 4.78,
						},
						feels_like: {
							day: 10.33,
							night: 7.43,
							eve: 8.43,
							morn: 3.82,
						},
						pressure: 1027,
						humidity: 52,
						dew_point: 2.13,
						wind_speed: 3.73,
						wind_deg: 324,
						wind_gust: 7.62,
						weather: [
							{
								id: 804,
								main: 'Clouds',
								description: 'Bedeckt',
								icon: '04d',
							},
						],
						clouds: 93,
						pop: 0,
						uvi: 4,
					},
					{
						dt: 1744282800,
						sunrise: 1744259135,
						sunset: 1744307855,
						moonrise: 1744298820,
						moonset: 1744256520,
						moon_phase: 0.42,
						summary: 'There will be partly cloudy today',
						temp: {
							day: 14.25,
							min: 4.8,
							max: 14.25,
							night: 8.36,
							eve: 11.41,
							morn: 5.9,
						},
						feels_like: {
							day: 12.96,
							night: 5.08,
							eve: 10.38,
							morn: 4.16,
						},
						pressure: 1015,
						humidity: 47,
						dew_point: 3.2,
						wind_speed: 7.85,
						wind_deg: 292,
						wind_gust: 13.16,
						weather: [
							{
								id: 804,
								main: 'Clouds',
								description: 'Bedeckt',
								icon: '04d',
							},
						],
						clouds: 94,
						pop: 0,
						uvi: 4,
					},
					{
						dt: 1744369200,
						sunrise: 1744345404,
						sunset: 1744394354,
						moonrise: 1744389420,
						moonset: 1744343460,
						moon_phase: 0.45,
						summary: 'Expect a day of partly cloudy with rain',
						temp: {
							day: 8.83,
							min: 6.25,
							max: 10.54,
							night: 6.25,
							eve: 7.58,
							morn: 7.25,
						},
						feels_like: {
							day: 6.33,
							night: 4.22,
							eve: 6.43,
							morn: 4.16,
						},
						pressure: 1010,
						humidity: 80,
						dew_point: 5.55,
						wind_speed: 6.05,
						wind_deg: 296,
						wind_gust: 11,
						weather: [
							{
								id: 500,
								main: 'Rain',
								description: 'Leichter Regen',
								icon: '10d',
							},
						],
						clouds: 100,
						pop: 0.2,
						rain: 0.24,
						uvi: 4,
					},
				],
			};
		}

		this.locationHandler = this.locationHandler.bind(this);
		this.handleRefresh = this.handleRefresh.bind(this);
	}

	componentDidMount() {
		let $this = this;

		$this.locationHandler();

		setInterval(() => {
			$this.locationHandler();
		}, 60000 * 5);
	}

	locationHandler() {
		let $this = this;

		function success(position) {
			const latitude = position.coords.latitude;
			const longitude = position.coords.longitude;

			log(latitude, longitude);

			let latLong = {
				lat: latitude,
				long: longitude,
			};

			$this.weatherHandler(latLong);
		}

		function error() {
			alert('Unable to retrieve your location');
		}

		navigator.geolocation.getCurrentPosition(success, error);
	}

	weatherHandler(latLong, res) {
		let $this = this;

		if (latLong === undefined) {
			return false;
		}

		axios
			.get(
				`https://api.openweathermap.org/geo/1.0/reverse?lat=${latLong['lat']}&lon=${latLong['long']}&limit=1&appid=226b0b119320d2a49978c8c3bdf7a06d`
			)
			.then((res) => {
				$this.setState({
					city: res.data[0].name,
				});
			});

		axios
			.get(
				`https://api.openweathermap.org/data/3.0/onecall?lat=${latLong['lat']}&lon=${latLong['long']}&units=metric&lang=de&appid=226b0b119320d2a49978c8c3bdf7a06d`
			)
			.then((res) => {
				log(res.data);

				log(res.data.current.weather[0].main.toLowerCase());

				let the_inWords =
					params['weather'][
						res.data.current.weather[0].main.toLowerCase()
					]['inWords']['de'];

				if (
					res.data.current.weather[0].id !== undefined &&
					params['weather'][res.data.current.weather[0].id] !==
						undefined
				) {
					the_inWords =
						params['weather'][res.data.current.weather[0].id][
							'inWords'
						]['de'];
				}

				let the_details =
					params['weather'][
						res.data.current.weather[0].main.toLowerCase()
					]['details'] || '';

				$this.setState({
					current: res.data.current,
					daily: res.data.daily,
					state: res.data.current.weather[0].main.toLowerCase(),
					icon:
						'./img/icons/' +
						res.data.current.weather[0].icon +
						'.png',
					inWords: the_inWords,
					details: the_details,
					sun: {
						sunrise: res.data.current.sunrise,
						sunset: res.data.current.sunset,
					},
				});
			});
	}

	sun() {
		let { sun, daily } = this.state;

		let icon = null;

		let sunEl = null;
		switch (true) {
			case Date.now() < sun.sunrise * 1000:
				sunEl = sun.sunrise;
				icon = './img/icons/sunrise.svg';
				break;
			case Date.now() >= sun.sunrise * 1000 &&
				Date.now() < sun.sunset * 1000:
				sunEl = sun.sunset;
				icon = './img/icons/sunset.svg';
				break;
			case new Date().setHours(new Date().getHours() + 2) >
				sun.sunset * 1000:
				if (daily[1] !== undefined) {
					sunEl = daily[1].sunrise;
					icon = './img/icons/sunrise.svg';
				}
				break;
			default:
				break;
		}

		return (
			<span className="sun-info">
				<img src={icon} className="sun-icon" alt="Sonne" />{' '}
				{new Date(sunEl * 1000)
					.toLocaleTimeString('de-DE')
					.slice(0, -3)}
			</span>
		);
	}

	handleRefresh() {
		this.locationHandler();
	}

	render() {
		if (this.state.current === undefined) {
			return false;
		}

		return (
			<ReactPullToRefresh
				onRefresh={this.handleRefresh}
				icon={
					<i className="material-symbols-outlined">arrow_downward</i>
				}>
				<div className="output">
					<div className="loca-details">
						{this.sun()} <span>{this.state.city}</span>
					</div>
					<div className="icon">
						<img src={this.state.icon} alt="Wetter" />
					</div>

					<div>
						<div
							className={`in-words ${this.state.state}`}
							dangerouslySetInnerHTML={{
								__html: this.state.inWords,
							}}></div>

						<div className="quick-info">
							<div className="temp">
								{this.state.current.temp.toFixed()} °C
							</div>
						</div>

						<div className="details">
							{this.state.details
								? this.state.details
								: 'Du kannst nach draußen schauen, um mehr Informationen zu erhalten.'}
						</div>

						<nav className="legal">
							<div>APP-Version _v1.5</div>
							<a
								href="https://philippunger.com/rechtlich/impressum.php"
								rel="noreferrer"
								target="_blank">
								Impressum
							</a>
							<a
								href="https://philippunger.com/rechtlich/datenschutz.php"
								rel="noreferrer"
								target="_blank">
								Datenschutz
							</a>
						</nav>
					</div>
				</div>
			</ReactPullToRefresh>
		);
	}
}

export default Location;
